import React, {
  useContext, useEffect, useMemo, useRef, useState
} from "react";
import styled from "styled-components";
import {RootContext} from "../root";
import {AssetContext} from "../provider/asset";
import Loading from "../components/loading";
import Error from "../components/error";
import Button from "../components/button";
import {FormattedMessage, useIntl} from "react-intl";
import axios from "axios";
import {LanguageContext} from "../provider/language";
import AssetFigure from "../components/assetfigure";
import {FigureProvider} from "../provider/figure";
import useSound from "use-sound";
// @ts-ignore
import sound from "./sound.mp4";
import config from "./cat.config.json";
import Particles, {initParticlesEngine} from "@tsparticles/react";
// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import {loadFull} from "tsparticles"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import NftImage from "../nft-image";
import {Helmet} from "react-helmet";
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

import "./claim-nft.css";
import GetNftButton from "../components/get-nft-button";
import TitleArea from "../components/titlearea";
import CollectionDetails from "../components/collection-details";
import Section from "../components/section";
// @ts-ignore
import CopyIcon from "../../designs/Icons/Copy_icon.svg";
import {Markdown} from "../components/markdown";
import FAQs from "../components/faqs/faqs";
import {CLAIM_NFT_FAQS} from "../constants/claim-nft-faqs";
import Divider from "../components/divider";
import {Tooltip, TooltipHandle} from "../components/tooltip";
import {navigate} from "gatsby";

const Logo = styled.img`
  height: 67px;
  width: 100%;
  object-fit: contain;
`;

const NftPiece = styled.img`
  border: 4px solid #b63d8f;
  max-height: 140px;
`;

const StampImg = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: contain;
`;

const PrivateKey = styled.div`
  overflow: auto;
  min-height: 8em;
`;

const ClaimNft = ({location}) => {
  const intl = useIntl();
  const {error, toggleLogos} = useContext(RootContext);
  const {locale} = useContext(LanguageContext);
  const {asset, collection, loading, collectionId, serialNumber, qrSuffix} = useContext(AssetContext);
  const [revealPrivateKey, setRevealPrivateKey] = useState(false);
  const faqRef = useRef(null);
  const tutorialRef = useRef(null);
  const tooltipRef = useRef<TooltipHandle>(null);

  const [clickCount, setClickCount] = useState(0);
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);

  const toggleKeyVisibility = () => {
    setRevealPrivateKey(!revealPrivateKey);
  };

  // Redirect to main page if stamp is not released yet.
  const releaseDate = collection ? collection.releaseDate.toDate() : new Date("2021-09-09T00:00:00+02:00");
  const printInfo = (date) => {
    console.info(`Stamp only released after ${date}.`);
    return true;
  };
  typeof window !== "undefined" && releaseDate && releaseDate > new Date() && printInfo(releaseDate) && navigate("/");

  const [_, setError] = useState(null);
  const [_privateKeyError, setPrivateKeyError] = useState(null);
  // Server-side generated private key for NFT claim.
  const [result, setResult] = useState<{secret: string; mnemonic: string} | null>(null);
  // Whether a cloud function invocation is currently being processed. Allows to change button state/visualization.
  const [isProcessing, setIsProcessing] = useState(false);
  // Controls tsParticles initialization.
  const [init, setInit] = useState(false);
  // Whether tsParticle animation and sound is currently playing.
  const [isPlaying, setIsPlaying] = useState(false);

  const handleSuccess = (result: any) => {
    setResult(result);
    setIsProcessing(false);
  };

  const handleError = (error) => {
    console.error(error);
    setPrivateKeyError(error);
    setIsProcessing(false);
  };

  const handleClaimButtonClick = async () => {
    if (revealPrivateKey) {
      toggleKeyVisibility();
      return;
    }
    toggleKeyVisibility();

    setIsProcessing(true);
    try {
      const res = await axios.post("https://europe-west6-dynamicelement-pli-stamp-dev.cloudfunctions.net/getNftDetails", {
        "collectionId": collectionId,
        "serialNumber": serialNumber,
        "qrSuffix": qrSuffix
      });
      handleSuccess(res.data);
    } catch (e) {
      console.error(e);
      handleError(e);
    }
  };

  // this should be run only once per application lifetime
  useEffect(() => {
    handleClaimButtonClick().then(() => {
    });
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadFull(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(() => (config), []);

  const [playSound, {pause}] = useSound(sound, {
    loop: true
  });

  const handleAssetFigureClick = () => {
    setClickCount((prevCount) => prevCount + 1);

    if (clickCount >= 4) {
      console.info(`Now ${!isPlaying ? "playing" : "pausing"} sound...`);
      if (!isPlaying) {
        playSound();
      } else {
        pause();
      }
      setIsPlaying(!isPlaying);
      toggleLogos();
    }

    // Clear the existing timeout if there is one
    if (clickTimeout) {
      clearTimeout(clickTimeout);
    }

    // Set a new timeout to reset the click count after 2 seconds
    setClickTimeout(
      setTimeout(() => {
        setClickCount(0);
      }, 1000)
    ); // 1000 milliseconds = 1 seconds
  };
  const copyMPtoCB = () => {
    navigator.clipboard.writeText(result!.mnemonic);
  };
  const copyPKToCB = () => {
    console.log({result});
    navigator.clipboard.writeText(result!.secret).then(() => {
      if (tooltipRef.current) {
        tooltipRef.current.showTooltip();
      }
    });
  };

  const scrollToTutorial = () => {
    tutorialRef.current.scrollIntoView({behavior: "smooth", block: "start"});
  };

  const scrollToFAQ = () => {
    faqRef.current.scrollIntoView({behavior: "smooth", block: "start"});
  };

  const nftButtonToPage = `/assets/?collectionId=${collectionId}&serialNumber=${serialNumber}&qrSuffix=${qrSuffix}`;

  if ((loading || !asset) && !error) {
    return <Loading />;
  } else if (error || !asset) {
    return <Error error={error} />;
  } else if (asset) {
    return (
      <>
        <FormattedMessage id="title.asset">
          {(title) => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        {isPlaying && (
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          />
        )}
        <div className="container my-4">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-6">
              <div className="row">
                <FigureProvider figureId={asset.figure.id}>
                  <NftImage onClick={handleAssetFigureClick} />
                </FigureProvider>
              </div>
              <div className="row" style={{textAlignLast: "center"}}>
                <div className="col-12 pt-3">
                  <GetNftButton
                    toPage={nftButtonToPage}
                    buttonTextId={"btn.cryptostamp.show"}
                  />
                </div>
              </div>
              <div className="row" style={{textAlignLast: "center"}}>
                <div className="col-12 py-3">
                  <Button gradient={true} disabled={true}>
                    <FormattedMessage id="btn.nft.show" />
                  </Button>
                </div>
              </div>
              <div className="row" style={{textAlignLast: "center"}}>
                <div className="col-12 pb-4">
                  <Button gradient={true} action={scrollToTutorial}>
                    <FormattedMessage id="btn.nft.get" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-6">
              <TitleArea
                title={collection.title[locale]}
                collectionId={asset.assetCollection.id}
                serialNumber={serialNumber}
                sequentialId={asset.sequentialId}
                qrSuffix={qrSuffix}
                collectionCount={collection.count}
                rubric={collection.rubric[locale]}
              />
              <Section
                title={intl.formatMessage({id: "section.stamp.nft.details"})}
                className="mt-4"
              >
                <CollectionDetails
                  collection={collection}
                  sequentialId={asset.sequentialId}
                  isCryptoCollection={true}
                />

                <div className="row">
                  <div className="col-12 text-right py-3 private-key-title">
                    <Button gradient={true} action={handleClaimButtonClick} className="btn-sm mb-3">
                      <FormattedMessage
                        id={
                          revealPrivateKey
                            ? "btn.nft.hide_private_key"
                            : "btn.nft.reveal_private_key"
                        }
                      />
                    </Button>
                  </div>
                </div>

                <div
                  ref={tutorialRef}
                  className={`key-container ${
                    revealPrivateKey ? "visible" : "hidden"
                  }`}
                >
                  {!_privateKeyError && (
                    <div>
                      <div className="private-key-container">
                        {isProcessing ? (
                          <div>
                            <Loading height="20vh" />
                          </div>
                        ) : (
                          <div className="container">
                            <div className="col-12 text-center ">
                              <div className="row private-key-title">
                                <div
                                  className="col-12"
                                  style={{textAlign: "left"}}
                                >
                                  <h3>
                                    <FormattedMessage id="btn.nft.private_key_for_import" />
                                  </h3>
                                </div>
                              </div>
                              <div className="row">
                                <div
                                  className="col-10"
                                  style={{textAlign: "left"}}
                                >
                                  <p>{result?.secret}</p>
                                </div>
                                <div className="col-2">
                                  <Tooltip
                                    ref={tooltipRef}
                                    message={intl.formatMessage({
                                      id: "claim-nft-page.private-key-section.tooltip.copied"
                                    })}
                                  >
                                    <Logo
                                      src={CopyIcon}
                                      className={"filter-white copy-icon"}
                                      onClick={copyPKToCB}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="container error-container">
                        <div className="row">
                          <div className="col-12 rfs-large">
                            <Markdown
                              content={intl.formatMessage({
                                id: "claim-nft-page.private-key-section.instructions.title"
                              })}
                            />
                          </div>
                        </div>
                        <div className="container">
                          <Markdown
                            content={intl.formatMessage({
                              id: "claim-nft-page.private-key-section.instructions.text"
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {!!_privateKeyError && (
                    <div>
                      <div className="private-key-container">
                        <div className="container error-container">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3>
                                <Markdown
                                  content={intl.formatMessage({
                                    id: "claim-nft-page.error.something-went-wrong"
                                  })}
                                />
                              </h3>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 text-center">
                              <Markdown
                                content={intl.formatMessage({
                                  id: "claim-nft-page.error.text.markdown"
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row divider-row">
                  <div className="col-12">
                    <Divider />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Markdown
                      content={intl.formatMessage({
                        id: "claim-nft-page.private-key-section.instructions.faq"
                      })}
                    />
                  </div>
                </div>
              </Section>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Button gradient={false} action={scrollToFAQ} className="float-right">
                <FormattedMessage id="claim-nft-page.private-key-section.faq" />
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Section
                    anchorTag="nft-faq-content"
                    title={intl.formatMessage({id: "faq"})}
                    className="my-5"
                    margin={false}
                  >
                    <div ref={faqRef}>
                      <FAQs
                        location={location}
                        faqs={CLAIM_NFT_FAQS}
                        error={null}
                        loading={false}
                        dividerColor={"#6c6c6c"}
                      />
                    </div>
                  </Section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Error error={"Unexpected state"} />;
  }
};

export default ClaimNft;

import React, {useContext, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {FigureContext} from "./provider/figure";
import {LanguageContext} from "./provider/language";

interface HasError {
    error?: any;
}

const Container = styled.div<HasError>`
  flex: 1;
  min-height: 240px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: ${(props) =>
    props.error
      ? "linear-gradient(67deg, #252f4a, #791b2c)"
      : ""};

  @media (max-width: ${(props) =>
      props.theme.breakpointsNo.lg - 1 + "px"}) and (min-width: ${(props) =>
      props.theme.breakpoints.md}) {
    min-height: 200px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 40vw;
    min-height: 160px;
  }
`;

const Note = styled.div`
  font-size: 14px;
  text-align: center;
  max-width: 80%;

  @media (max-width: ${(props) => props.theme.breakpointsNo.lg - 1 + "px"}) {
    font-size: 12px;
  }
`;

const Image = styled.img`
  width: 90%;
  max-width: 300px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 23%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 40px;
  }
`;


const StampImg = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: contain;
`;

/**
 * Displays the NFT image of a given asset.
 */
const NftImage = ({onClick = () => {}}) => {
    const {locale} = useContext(LanguageContext);
    const {figure, loading, error} = useContext(FigureContext);

    /*const [figure, setFigure] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setLoading(false);
        setFigure({name: {en: "foo"}})
    }, [])*/

    if ((loading || !figure) && !error) {
        return (
          <Container >
            <div className="m-spinner--swirl mb-3"></div>
            <Note>
              <FormattedMessage id="component.nft-image.loading.text" />
            </Note>
          </Container>
        );
    }
    else if (figure) {
        const figureIcon = figure.iconUrl.nft;
        // TODO Fix style & size for mobile/smaller screens
        return (
            <StampImg src={figureIcon} alt={figure.iconAlt[locale]} onClick={onClick} style={{cursor: "pointer"}} className={"mb-4"}/>
        ); 
    } else {
        return <Container error={error}>{`${error}`}</Container>;
    }
};

export default NftImage;
export const CLAIM_NFT_FAQS = [
  {
    title: {
      de: "Was verbirgt sich hinter der neuen NFT-Kryptobriefmarke?",
      en: "What is the new NFT crypto stamp?"
    },
    content: {
      markdown: {
        de: "Die neue Kryptobriefmarke der Philatelie Liechtenstein verbindet nun nicht nur die klassische Briefmarke mit einem digitalen Abbild in der App sondern zusätzlich mit einem NFT (Non-Fungible-Token). Der NFT kann in ein persönliches Digital Wallet übertragen werden, dort aufbewahrt oder online gehandelt werden. Der Besitz und Lebenslauf dieser Kryptobriefmarke ist digital unveränderbar abgebildet.",
        en: "Philately Liechtenstein's new crypto stamp now not only combines the classic stamp with a digital image in the app, but also with an NFT (non-fungible token). The NFT can be transferred to a personal digital wallet, stored there or traded online. The ownership and history of this crypto stamp is digitally immutable."
      }
    },
    status: "LIVE",
    sortKey: 0
  },
  {
    title: {
      de: "Was ist ein NFT?",
      en: "What is an NFT?"
    },
    content: {
      markdown: {
        de: "Die Abkürzung NFT steht für Non Fungible Token. Leichter gesagt: Der NFT ist eine nicht-austauschbare Wertmarke. Ein Token kann als digitale Besitzurkunde übersetzt werden, die beim NFT nicht fälschbar ist. Wo befinden sich nun also diese unfälschbaren digitalen Besitzurkunden? Die NTFs werden digital auf einer Blockchain gespeichert – quasi einem verteilten öffentlichen Hauptbuch, welches Transaktionen aufzeichnet. Jeder NFT hat einzigartige Identifikationscodes, wodurch sie sich voneinander unterscheiden. Die Überprüfung vom Besitz und Übertragung an einen neuen Eigentümer können dadurch sicher gewährleistet werden.",
        en: "The abbreviation NFT stands for Non Fungible Token. A token can be translated as a digital certificate of ownership, which cannot be forged. So where are these unforgeable digital certificates of ownership located? The NTFs are stored digitally on a blockchain - a kind of distributed public ledger that records transactions. Each NFT has unique identification codes that distinguish them from each other. Verification of ownership and transfer to a new owner can thus be securely guaranteed."
      }
    },
    status: "LIVE",
    sortKey: 1
  },
  {
    title: {
      de: "Was ist ein Digital Wallet und wie lege ich es an?",
      en: "What is a digital wallet and how do I create one?"
    },
    content: {
      markdown: {
        de: "Wallets sind ein essentieller Teil des Anlageprozesses, da sie es Anlegern ermöglichen sowohl Kryptowährungen als auch NFTs sicher zu speichern. Es gibt verschiedene Digital Wallet Anbieter, z.B. MetaMask. Entscheidend ist, dass der Digital Wallet Anbieter die Blockchain Polygon unterstützt, damit der NFT übertragen werden kann. Sie können das Digital Wallet entweder als Browser-Erweiterung für Ihren Desktop und als App auf Ihrem Smartphone herunterladen. Wir empfehlen die App-Anwendung. Weitere Schritte entnehmen Sie der Anleitung vom jeweiligen Anbieter ([hier für MetaMask](https://support.metamask.io/de/getting-started/getting-started-with-metamask/#))",
        en: "Wallets are an essential part of the investment process as they allow investors to securely store both cryptocurrencies and NFTs. There are various digital wallet providers, e.g. MetaMask. It is crucial that the digital wallet provider supports the Polygon blockchain so that the NFT can be transferred. You can download the digital wallet either as a browser extension for your desktop or as an app on your smartphone. We recommend the app application. Further steps can be found in the instructions from the respective provider ([here for MetaMask](https://support.metamask.io/de/getting-started/getting-started-with-metamask/#))"
      }
    },
    status: "LIVE",
    sortKey: 2
  },
  {
    title: {
      de: "Brauche ich ein Digital Wallet?",
      en: "Do I need a digital wallet?"
    },
    content: {
      markdown: {
        de: "Nicht unbedingt. Der Briefmarkenblock der neuen NFT-Kryptobriefmarken der Philatelie Liechtenstein fungiert als «Paper Wallet». Ein Paper Wallet ist ein gedrucktes Stück Papier, das Schlüssel und QR-Code für NFT-Transaktionen enthält. Das Token an sich, die digitale Besitzurkunde, ist also im physischen Briefmarkenblock integriert. So können Sie die Briefmarke in Papierform als Sammlerstück aufbewahren. Auch den digitalen Zwilling können Sie nach dem Scan in der App oder auf der Website wie gewohnt ansehen. Möchten Sie jedoch IhrenNFT in einem persönlichen digitalen Wallet aufbewahren und/oder diesen über NFT-Plattformen handeln, empfehlen wir Ihnen ein polygon-kompatibles Digital Wallet zu erstellen.",
        en: "Not necessarily. The stamp of the new NFT crypto stamps from Philately Liechtenstein acts as a «paper wallet». A paper wallet is a printed piece of paper that contains the key and QR code for NFT transactions. The token itself, the digital certificate of ownership, is therefore integrated into the physical stamp block. This means you can keep the stamp in paper form as a collector's item. After scanning, you can also view the digital twin in the app or on the website as usual. However, if you would like to store yourNFT in a personal digital wallet and/or trade it via NFT platforms, we recommend that you create a polygon-compatible digital wallet."
      }
    },
    status: "LIVE",
    sortKey: 3
  },
  {
    title: {
      de: "Was kostet ein Transfer vom Paper Wallet in das Digital Wallet?",
      en: "What does a transfer from the paper wallet to the digital wallet cost?"
    },
    content: {
      markdown: {
        de: "Für den initialen Transfer vom Paper Wallet in das Digital Wallet entstehen für Sie keine weiteren Kosten, da wir ausreichend MATIC Währung für eine Transaktion bereitstellen, sofern Sie Ihren NFT innerhalb von 6 Monaten nach Erscheinungsdatum transferieren.",
        en: "There are no additional costs for the initial transfer from the paper wallet to the digital wallet, as we provide sufficient MATIC currency for a transaction, provided you transfer your NFT within 6 months of the release date."
      }
    },
    status: "LIVE",
    sortKey: 4
  },
  {
    title: {
      de: "Wie kommt der NFT von meiner Briefmarke in mein Digital Wallet?",
      en: "How does the NFT get from my stamp to my digital wallet?"
    },
    content: {
      markdown: {
        de: "**Via QR-Code Scan:**\n\n" +
          "1. Scannen Sie den SQR-Code auf der Briefmarke mittels Ihrer Smartphone-Kamera oder QR-Code Reader App (nicht in der Briefmarke 4.1 App). Sie werden nun auf eine Webseite weitergeleitet, die lediglich Ihrer Kryptobriefmarke vorbehalten ist. *Wichtig*: Bitte kopieren und versenden Sie den Link nicht an unbefugte Personen.\n" +
          "2. Klicken Sie auf «NFT HOLEN». Es öffnet sich ein Abschnitt mit Ihrem Private Key und einer weiteren Anleitung. Befolgen Sie die hier beschriebenen Schritte. Wichtig: Der Private Key ist ein geheimer Code, der verwendet wird, um die Transaktion des NFTs in Ihr persönliches Digital Wallet zu autorisieren. Bewahren Sie ihn sicher auf und teilen Sie ihn nicht mit unbefugten Personen.",
        en: "**Via QR-Code Scan:**\n\n" +
          "1. Scan the SQR code on the stamp using your smartphone camera or QR code reader app (not in the Stamp 4.1 app). You will now be redirected to a website that is only reserved for your crypto stamp. Important: Please do not copy and send the link to unauthorized persons.\n" +
          "2. Click on «GET NFT». A section with your private key and further instructions will open. Follow the steps described here. Important: The private key is a secret code that is used to authorize the transaction of the NFT to your personal digital wallet. Keep it safe and do not share it with unauthorized persons."
      }
    },
    status: "LIVE",
    sortKey: 5
  },
  {
    title: {
      de: "Auf welcher Blockchain liegen die neuen NFT-Kryptobriefmarken der Philatelie Liechtenstein?",
      en: "On which blockchain are the new NFT crypto stamps from Philately Liechtenstein stored?"
    },
    content: {
      markdown: {
        de: "Die neuen NFT-Kryptobriefmarken der Philatelie Liechtenstein liegen auf der Polygon-Blockchain. Wichtige Eigenschaften der Polygon-Blockchain sind schnelle Transaktionszeiten und niedrige Transaktionsgebühren. Die Transaktionen werden nach dem sogenannten «Proof-of-Stake»-Prinzip verarbeitet, ein Verfahren, welches sehr viel weniger Energie benötigt als das «Proof-of-Work»-Prinzip, das bei Kryptowährungen wie Bitcoin angewendet wird. Die Blockchain Polygon profitiert zudem von der Sicherheit der Hauptchain Ethereum, auf welche Polygon aufsetzt.",
        en: "Philately Liechtenstein's new NFT crypto stamps are based on the Polygon blockchain. Important features of the Polygon blockchain are fast transaction times and low transaction fees. The transactions are processed according to the so-called «proof-of-stake» principle, a process that requires much less energy than the «proof-of-work» principle used for cryptocurrencies such as Bitcoin. The Polygon blockchain also benefits from the security of the main Ethereum chain, on which Polygon is based."
      }
    },
    status: "LIVE",
    sortKey: 6
  }
];
